import { TAddress, TCustomer, TCustomerAdd, TCustomerContact, TParticipantAdd } from 'models';
import axios, { AxiosError } from 'axios';
import { addCountryCode, preflightAPICall } from 'utils';
import { API_CUSTOMER_PROFILE_URL, DEFAULT_RESIDENT_COUNTRY_CODE } from 'config/api';
import { SOLE_TRADER_CUSTOMER_TYPE } from 'config/consts';
import {
  ResultsIcon,
  ResultsItem,
  ResultsItemData,
  ResultsItemDataTitle
} from './styles';
import { DefaultIcon } from 'styles/common';
import React from 'react';

export const addCustomerCommonFields = (form: TCustomerAdd | TParticipantAdd, requestObject: TCustomer, addresses: TAddress[], contacts: TCustomerContact[]) => {
  requestObject.props = {
    ...requestObject.props,
    ...prepareCustomerDataBeforeSend(form, form)
  };

  if (form.preferred_communication_channel?.length) {
    requestObject.props.preferred_communication_channel = form.preferred_communication_channel;
  }

  if (form.preferred_communication_language) {
    requestObject.props.preferred_communication_language = form.preferred_communication_language;
  }

  if (form.customer_manager_id) {
    requestObject.props.customer_manager_id = form.customer_manager_id;
  }

  if (addresses?.length) {
    requestObject.addresses = addresses;
  }

  if (contacts?.length) {
    if (form.resident) {
      contacts.forEach(contact => {
        if (contact.type === 'phone' && contact.value && !contact.value.startsWith(window._env_.DEFAULT_COUNTRY_CODE)) {
          contact.value = addCountryCode(contact.value);
        }
      });
    }

    const filteredContacts = contacts.filter(contact => contact.value);

    if (filteredContacts.length) {
      requestObject.contacts = filteredContacts;
    }
  }

  return requestObject;
};

export const prepareCustomerDataBeforeSend = (customerData: TCustomer | TCustomerAdd | TParticipantAdd, customerFormData: TCustomer['props'] | TCustomerAdd | TParticipantAdd) => {
  let customerProps: Partial<TCustomer['props']> = {};

  switch (customerData.type) {
    case 'individual':
      customerProps = {
        first_name: customerFormData.first_name,
        last_name: customerFormData.last_name,
        family_name: customerFormData.family_name,
        birthday: customerFormData.birthday,
        sex: customerFormData.sex,
        resident: typeof customerFormData.resident === 'boolean' ? customerFormData.resident : true,
        countryCode: customerFormData.countryCode || (customerFormData.resident ? DEFAULT_RESIDENT_COUNTRY_CODE : '')
      };

      if (typeof customerFormData.resident === 'boolean' && !customerFormData.resident) {
        customerProps.idCode = customerFormData.idCode;
      } else {
        customerProps.pesel = customerFormData.pesel;
      }

      break;
    case 'company':
      customerProps = {
        company_type: customerFormData.company_type,
      };

      if (customerFormData.company_type === SOLE_TRADER_CUSTOMER_TYPE) {
        customerProps.first_name = customerFormData.first_name;
        customerProps.last_name = customerFormData.last_name;
        customerProps.family_name = customerFormData.family_name;
        customerProps.pesel = customerFormData.pesel;
        customerProps.birthday = customerFormData.birthday;
        customerProps.sex = customerFormData.sex || ' ';
        customerProps.company_name = customerFormData.company_name || undefined;
        customerProps.regon = customerFormData.regon || undefined;
        customerProps.nip = customerFormData.nip || undefined;
        customerProps.pkdCode = customerFormData.pkdCode || undefined;
      } else {
        customerProps.company_name = customerFormData.company_name;
        customerProps.regon = customerFormData.regon;
        customerProps.nip = customerFormData.nip || undefined;
        customerProps.pkdCode = customerFormData.pkdCode || undefined;
        customerProps.pkdDescription = customerFormData.pkdDescription || undefined;
      }
  }

  customerProps.draft = undefined;

  return customerProps;
};

export const getLatestUserRevision = (customerId: string, onSuccess: (data: TCustomer) => void, onError: (error: AxiosError) => void) => {
  preflightAPICall(() => {
    axios.get(API_CUSTOMER_PROFILE_URL.replace('%USER_ID%', customerId)).then(response => {
      const data = response.data;
      onSuccess(data);
    }).catch(error => {
      onError(error);
    });
  });
};

export const createResults = (results: TCustomer[], onSubmitCustomer: (customer: TCustomer) => void) => {

  if (results) {
    return results.map(item => (
      <ResultsItem
        onClick={() => onSubmitCustomer(item)}
        key={item.id}
      >
        <ResultsIcon icon={item.props.regon ? 'company' : 'user'} />
        <ResultsItemData>
          <ResultsItemDataTitle>
            {item.props.pesel || item.props.regon}
          </ResultsItemDataTitle>
        </ResultsItemData>
        <DefaultIcon icon="arrow_nav_right" />
      </ResultsItem>
    ));
  }

  return [];

};
