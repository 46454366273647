import axios from 'axios';
import { logout } from './logout';

export interface IOptions {
  authHeader?: string,
  broker?: string,
}

const xDomainApiWhiteList = [
  '/api/v1/master',
  '/api/v1/idd',
  '/api/v1/gdpr',
  '/api/v1/customers',
  '/api/v1/qmt'
];

const schemasPath = '/schemas';

export const configAxios = (options: IOptions) => {
  if (options.authHeader) {
    axios.defaults.headers.common['Authorization'] = options.authHeader;
  }

  if (options.broker) {
    axios.defaults.headers.common['X-Broker'] = options.broker;
  }

  const userString = localStorage.getItem('user');

  if (userString && userString.indexOf('masquerade') >= 0) {
    delete axios.defaults.headers.common['X-Broker'];
  }

  axios.interceptors.response.use(response => response, error => {
    if (error.response && error.response?.status === 401) {
      logout();
    }

    return Promise.reject(error);
  });

  axios.interceptors.request.use((config) => {

    if (config.headers?.common?.['X-Domain']
      && config.url
      && (config.url?.includes(schemasPath) || !xDomainApiWhiteList.some(v => config.url?.includes(v)))) {
      delete config.headers.common?.['X-Domain'];
    }

    return config;
  }, error => Promise.reject(error));
};
