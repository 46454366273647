import React, { useEffect, useState } from 'react';

import {
  JSONSchema,
  JSONSchemaProp,
  TCommunicationChanel,
  TError,
  TCustomerType,
  TCustomerAdd,
  TUserLocale,
  TCustomer,
  TFetchStatus,
  IFormGroupItemInput,
  TQuoteParticipantRole,
  TParticipantAdd
} from 'models';
import {
  Communication,
  CommunicationItems,
  CommunicationLabel,
  DrawerDataFormAdditional,
  DrawerDataFormAdditionalRow,
  DrawerUserType,
  DrawerUserTypeIcon,
} from '../styles';
import { DrawerUserTypeLabel, DrawerUserTypeValue } from 'app/Components/common/Drawer/styles';
import { TUserProfile } from 'app/Pages/Clients/common';
import { Button, Checkbox, Select } from '@insly/qmt-reactjs-ui-lib';
import {
  CUSTOMER_COMMON_VALIDATION_FIELDS,
  CUSTOMER_VALIDATION_FIELDS,
  TEMP_CUSTOMER_MANAGERS,
  CUSTOMER_TYPE_ICONS,
} from 'config/itemLists';
import {
  getErrorFieldsFromErrors,
  processErrorsToString,
  translateEnumItemInScheme,
  translateTitleInScheme,
  getOptionsFromEnum,
  validate,
  setRules,
  translate,
  serverDateTimeFormatter,
  setCustomerDataFromPesel,
  preflightAPICall,
  errorNotification,
  IsRequiredForSoleTraderAndIndividual,
  preventScroll,
} from 'utils';
import { IFormGroupItem } from '@insly/qmt-reactjs-ui-lib/dist/components/FormGroup';
import { FormGroupStyled } from 'styles/common';
import { useApp } from 'context/App';
import axios, { AxiosError } from 'axios';
import apiConfig from 'config/api';
import { CONTENT_CLASS_NAME, SOLE_TRADER_CUSTOMER_TYPE } from 'config/consts';
import {
  customerTypeValidation,
  pkdCodeMatcher,
  regexpValidation
} from 'app/Pages/Quote/Profile/body/Participants/common/utils';
import { POLICY_HOLDER } from 'app/Pages/Quote/Profile/body/Participants/common/consts';
import { createResults } from 'app/Components/common/Customer';

let CancelToken = axios.CancelToken;
let cancelTokenSource = CancelToken.source();

const PAGE_SIZE = 5;

export type TOptionsType = 'pesel' | 'regon';

export const CustomerProfile = ({
  roles,
  form,
  schemaProps,
  userLocale,
  handleChange,
  handleChangeMultiple,
  errors,
  onSubmitCustomer,
  isClients,
  readOnly,
}: {
  roles?: TQuoteParticipantRole[],
  form: TUserProfile,
  schemaProps: JSONSchema,
  userLocale: TUserLocale,
  handleChange: (key: string, value: string) => void,
  handleChangeMultiple: (form: Partial<TUserProfile>) => void,
  errors: TError[],
  onSubmitCustomer?: (item: TCustomer) => void,
  isClients?: boolean,
  readOnly?: boolean,
}) => {

  const [fetchStatus, updateFetchStatus] = useState<TFetchStatus>(null);
  const [results, updateResults] = useState<JSX.Element[]>([]);
  const [optionsType, setOptionsType] = useState<TOptionsType>();

  const { showNotification } = useApp();

  useEffect(() => {
    preventScroll(`.${CONTENT_CLASS_NAME}`);
    return () => preventScroll(`.${CONTENT_CLASS_NAME}`, true);
  }, []);

  useEffect(() => {
    updateResults([]);
  }, [form.type]);

  const callSearch = (fieldType: TOptionsType) => {

    if (cancelTokenSource) {
      cancelTokenSource.cancel('Operation canceled by the user.');
      CancelToken = axios.CancelToken;
      cancelTokenSource = CancelToken.source();
    }

    setOptionsType(fieldType);
    updateFetchStatus('loading');
    updateResults([]);
    preflightAPICall(() => {
      let apiUrl = `${apiConfig.CUSTOMER}/customers?fields[type]=${form.type}&fields[unique_idcode]=${form[fieldType]}&page_size=${PAGE_SIZE}`;

      if (form.company_type === SOLE_TRADER_CUSTOMER_TYPE) apiUrl +=`&fields[props.company_type]=${form.company_type}`;

      if (form.type === 'individual') {
        apiUrl += `&fields[props.resident]=${form.resident}`;
      }

      axios.get(apiUrl, {
        cancelToken: cancelTokenSource ? cancelTokenSource.token : undefined,
      })
        .then(response => {
          const results = createResults(response.data.results, (item) => {
            if (onSubmitCustomer) {
              updateResults([]);
              onSubmitCustomer(item);
            }
          });
          updateResults(results);
          // updateResults(response.response.results || []);
          updateFetchStatus('success');
        })
        .catch(error => {
          updateResults([]);
          updateFetchStatus('failed');
          if (!axios.isCancel(error)) {
            errorNotification(error, showNotification, false, 'quote');
          }
        });
    });

  };

  const errorFields = errors?.length ? getErrorFieldsFromErrors(CUSTOMER_VALIDATION_FIELDS, errors) : [];

  const regonField = {
    type: onSubmitCustomer ? 'search' : 'input',
    options: {
      required: true,
      error: errorFields.includes('regon') ? 'error' : undefined,
      name: 'regon',
      label: translateTitleInScheme(schemaProps?.regon),
      value: form.regon,
      handleChange: handleChange,
      results: optionsType === 'regon' ? results : undefined,
      callSearch: () => callSearch('regon'),
      fetchStatus: optionsType === 'regon' && fetchStatus,
    }
  };

  const nipField = {
    type: 'input',
    options: {
      error: errorFields.includes('nip') ? 'error' : undefined,
      name: 'nip',
      label: translateTitleInScheme(schemaProps?.nip),
      value: form.nip,
      handleChange: handleChange,
    }
  };

  const companyNameField = {
    type: 'input',
    options: {
      required: form.company_type !== SOLE_TRADER_CUSTOMER_TYPE,
      error: errorFields.includes('company_name') ? 'error' : undefined,
      name: 'company_name',
      capitalized: true,
      label: translateTitleInScheme(schemaProps?.company_name),
      value: form.company_name,
      handleChange: handleChange,
    }
  };

  const pkdCodeField = {
    type: 'input',
    options: {
      placeholder: '_ _ . _ _ . _',
      error: errorFields.includes('pkdCode') ? 'error' : undefined,
      name: 'pkdCode',
      label: translateTitleInScheme(schemaProps?.pkdCode),
      value: pkdCodeMatcher(form.pkdCode),
      handleChange: handleChange,
    }
  };

  const pkdDescriptionField = {
    type: 'input',
    options: {
      error: errorFields.includes('pkdDescription') ? 'error' : undefined,
      name: 'pkdDescription',
      label: translateTitleInScheme(schemaProps?.pkdDescription),
      value: form.pkdDescription,
      handleChange: handleChange,
    }
  };

  const idCodeField = (form.resident === false) ? {
    type: 'input',
    options: {
      name: 'idCode',
      error: errorFields.includes('idCode') ? 'error' : undefined,
      required: true,
      label: translateTitleInScheme(schemaProps?.idCode),
      value: form.idCode,
      handleChange: handleChange,
    }
  } : {
    type: onSubmitCustomer ? 'search' : 'input',
    options: {
      name: 'pesel',
      results: optionsType === 'pesel' ? results : undefined,
      callSearch: () => callSearch('pesel'),
      fetchStatus: optionsType === 'pesel' && fetchStatus,
      error: errorFields.includes('pesel') ? 'error' : undefined,
      required: true,
      label: translateTitleInScheme(schemaProps?.pesel),
      value: form.pesel,
      handleChange: handleChange,
      onBlured: () => setCustomerDataFromPesel(form.pesel as string, handleChangeMultiple, false, showNotification)
    }
  };

  const genderField = {
    type: 'select',
    options: {
      name: 'sex',
      error: errorFields.includes('sex') ? 'error' : undefined,
      label: translateTitleInScheme(schemaProps?.sex),
      value: form.sex,
      placeholder: schemaProps?.sex.placeholder || translate({ key: 'common.select' }),
      required: isClients ? true : IsRequiredForSoleTraderAndIndividual(form.company_type, roles),
      options: getOptionsFromEnum(schemaProps?.sex) || [],
      handleChange: (name: string, value: string) => handleChange(name, value),
    }
  };

  const firstNameField = {
    type: 'input',
    options: {
      name: 'first_name',
      capitalized: true,
      error: errorFields.includes('first_name') ? 'error' : undefined,
      label: translateTitleInScheme(schemaProps?.first_name),
      value: form.first_name,
      required: true,
      handleChange: (key: string, value: string) => handleChange(key, value.trim()),
    }
  };

  const lastNameField = {
    type: 'input',
    options: {
      name: 'last_name',
      capitalized: true,
      error: errorFields.includes('last_name') ? 'error' : undefined,
      required: true,
      label: translateTitleInScheme(schemaProps?.last_name),
      value: form.last_name,
      handleChange: handleChange,
    }
  };

  const familyNameField = {
    type: 'input',
    options: {
      name: 'family_name',
      // @ts-ignore
      capitalized: true,
      error: errorFields.includes('family_name') ? 'error' : undefined,
      label: translateTitleInScheme(schemaProps?.family_name),
      value: form.family_name,
      handleChange: handleChange,
    }
  } as IFormGroupItemInput;

  const birthdayField = {
    type: 'date',
    options: {
      name: 'birthday',
      required: isClients ? true : IsRequiredForSoleTraderAndIndividual(form.company_type, roles),
      userLocale: userLocale,
      error: errorFields.includes('birthday') ? 'error' : undefined,
      label: translateTitleInScheme(schemaProps?.birthday),
      value: form.birthday ? new Date(form.birthday) : undefined,
      maxDate: new Date(),
      handleChange: (name: string, value: Date | null | undefined) => handleChange(name, value ? serverDateTimeFormatter(value, true) : ''),
    }
  };

  let customerIdCodes = [
    regonField,
    {
      type: 'input',
      options: {
        error: errorFields.includes('nip') ? 'error' : undefined,
        name: 'nip',
        label: translateTitleInScheme(schemaProps?.nip),
        value: form.nip,
        handleChange: handleChange,
      }
    }
  ];

  let companyFields: IFormGroupItem[][] = [
    [
      companyNameField,
      {
        type: 'select',
        options: {
          name: 'company_type',
          error: errorFields.includes('company_type') ? 'error' : undefined,
          label: translateTitleInScheme(schemaProps?.company_type),
          value: form.company_type,
          required: true,
          //@HACK: https://insly.atlassian.net/browse/INSLYPROD-6529
          options: getOptionsFromEnum(schemaProps?.company_type).filter(option => option.key !== SOLE_TRADER_CUSTOMER_TYPE),
          placeholder: translate({ key: 'common.select' }),
          notFoundText: translate({ key: 'common.nothing_found' }),
          handleChange: (name, value) => {
            updateResults([]);
            handleChange(name as string, value as string);
          },
        }
      },
    ] as IFormGroupItem[],
    [
      pkdCodeField,
      pkdDescriptionField
    ] as IFormGroupItem[],
  ];

  if (form.company_type === SOLE_TRADER_CUSTOMER_TYPE) {
    let firstRow = [
      idCodeField,
      birthdayField,
      genderField
    ] as IFormGroupItem[];

    let personalFields = [
      firstNameField,
      lastNameField,
      familyNameField,
    ] as IFormGroupItem[];

    companyFields = [
      firstRow,
      personalFields,
      [
        companyNameField,
        regonField,
        nipField
      ] as IFormGroupItem[],
      [
        pkdCodeField,
      ] as IFormGroupItem[],
    ];
  } else {
    companyFields = [
      // @ts-ignore
      customerIdCodes,
      ...companyFields,
    ];
  }


  const peselAndSex = [
    idCodeField,
    genderField,
    familyNameField,
  ] as IFormGroupItem[];

  return (
    <>
      {form.type === 'individual' ? (
        <FormGroupStyled
          error={errors?.length ? processErrorsToString(CUSTOMER_VALIDATION_FIELDS, errors) : undefined}
          items={[
            peselAndSex,
            [
              firstNameField,
              lastNameField,
            ],
            [
              birthdayField,
            ]
          ] as IFormGroupItem[][]}
          disabled={readOnly}
        />
      ) : (
        <>

          <FormGroupStyled
            items={companyFields}
            error={errors?.length ? processErrorsToString(CUSTOMER_VALIDATION_FIELDS, errors) : undefined}
            disabled={readOnly}
          />
        </>

      )}
    </>
  );

};

export const renderUserType = (
  type: TCustomerType,
  schemaValue: JSONSchemaProp,
  handleChange?: (key: string, value: string) => void,
  userTypesEnum: TCustomerType[] = [],
  name: string = 'type',
  addUser?: boolean,
  readOnly?: boolean,
) => (
  addUser ? (
    <DrawerUserType addUser={addUser}>
      <Select
        name={name}
        label={translateTitleInScheme(schemaValue)}
        icon={CUSTOMER_TYPE_ICONS[type]}
        value={type}
        disabled={readOnly}
        options={[
          ...getOptionsFromEnum(schemaValue),
          {
            key: SOLE_TRADER_CUSTOMER_TYPE,
            value: translate({ key: 'customer.type.sole_trader' })
          }
        ]}
        handleChange={(name, value) => handleChange ? handleChange(name as string, value as string) : undefined}
        placeholder={translate({ key: 'common.select' })}
        notFoundText={translate({ key: 'common.nothing_found' })}
      />
    </DrawerUserType>
  ) : (
    <DrawerUserType>
      <DrawerUserTypeIcon icon={CUSTOMER_TYPE_ICONS[type]} />
      <div>
        <DrawerUserTypeLabel>{translateTitleInScheme(schemaValue)}</DrawerUserTypeLabel>
        <DrawerUserTypeValue>{type === SOLE_TRADER_CUSTOMER_TYPE ? translate({ key: 'customer.type.sole_trader' }) : translateEnumItemInScheme(schemaValue, type)}</DrawerUserTypeValue>
      </div>
    </DrawerUserType>
  )
);

export const renderLanguageAndAgentInfo = (
  form: TUserProfile,
  schemaProps: JSONSchema,
  handleChange: (key: string, value: string) => void
) => (
  <DrawerDataFormAdditional>
    <DrawerDataFormAdditionalRow>
      <Select
        icon="globe"
        name="preferred_communication_language"
        value={form.preferred_communication_language}
        label={translateTitleInScheme(schemaProps.preferred_communication_language)}
        options={getOptionsFromEnum(schemaProps.preferred_communication_language) || []}
        handleChange={(name, value) => handleChange(name as string, value as string)}
        placeholder={translate({ key: 'common.select' })}
        notFoundText={translate({ key: 'common.nothing_found' })}
      />
    </DrawerDataFormAdditionalRow>
    <DrawerDataFormAdditionalRow>
      <Select
        icon="agent"
        name="customer_manager_id"
        value={form.customer_manager_id}
        label={translateTitleInScheme(schemaProps.customer_manager_id)}
        options={TEMP_CUSTOMER_MANAGERS}
        handleChange={(name, value) => handleChange(name as string, value as string)}
        placeholder={translate({ key: 'common.select' })}
        notFoundText={translate({ key: 'common.nothing_found' })}
      />
    </DrawerDataFormAdditionalRow>
  </DrawerDataFormAdditional>
);

export const renderCommunication = (schemaProps: JSONSchema, preferred_communication_channel: TCommunicationChanel[], handleChange?: (key: string, value: string[]) => void) => (
  <Communication marginTop={!!handleChange}>
    <CommunicationLabel>{translateTitleInScheme(schemaProps.preferred_communication_channel)}:</CommunicationLabel>
    <CommunicationItems>
      {renderCommunicationItems(schemaProps, preferred_communication_channel || [], handleChange)}
    </CommunicationItems>
  </Communication>
);

const renderCommunicationItems = (schemaProps: JSONSchema, items: string[], handleChange?: (key: string, value: string[]) => void) => {

  const onChangeArray = (name: string, value: string) => {
    const itemIndex = items.indexOf(value);
    const itemsProcessed = [...items];
    if (itemIndex >= 0) {
      itemsProcessed.splice(itemIndex, 1);
    } else {
      itemsProcessed.push(value);
    }

    handleChange && handleChange(name, itemsProcessed);
  };

  return schemaProps.preferred_communication_channel.items?.enum?.map((item, index) => (
    <Checkbox
      key={index}
      name="preferred_communication_channel"
      checked={items?.includes(item)}
      readOnly={!handleChange}
      handleChange={handleChange ? (name) => onChangeArray(name as string, item) : undefined}
      label={translateEnumItemInScheme(schemaProps.preferred_communication_channel as JSONSchemaProp, item)}
    />
  ));
};

export const validateCustomer = ({
  customerSchema,
  schemaProps,
  form,
  excludedFields,
}: {
  customerSchema: JSONSchemaProp,
  schemaProps: JSONSchemaProp['properties'],
  form: Partial<TCustomerAdd>,
  excludedFields: string[],
}) => {
  let fieldsToValidate = customerSchema.required?.length ? [...customerSchema.required] : [];
  const notRequiredFields = ['nip'];

  if (form.type === 'individual' && customerSchema.then?.properties?.props?.then?.required?.length) {
    fieldsToValidate.push(...customerSchema.then?.properties?.props?.then?.required as string[]);
  } else if (form.type === 'company' && customerSchema.else?.properties?.props?.required?.length) {
    fieldsToValidate.push(...customerSchema.else?.properties?.props?.required as string[]);
  }

  fieldsToValidate = [...fieldsToValidate, ...customerTypeValidation(form as TParticipantAdd, POLICY_HOLDER)];

  // Add not required fields to check
  notRequiredFields.forEach(field => {
    if (typeof form[field as keyof TCustomerAdd] !== 'undefined' && form[field as keyof TCustomerAdd] !== '') {
      fieldsToValidate.push(field);
    }
  });

  let errorsUI: TError[] = [];

  fieldsToValidate.forEach(item => {
    if (excludedFields.includes(item)) {
      return;
    }

    let props = schemaProps;

    if (item === 'type') {
      props = customerSchema.properties;
    }

    const { pattern, regExpErrorMessage } = regexpValidation(item, 'first_name', 'last_name');

    const rules = setRules({
      props,
      item,
      pattern,
      regExpErrorMessage
    });

    const errors = validate({
      field: item,
      fieldName: props && props[item] ? translateTitleInScheme(props[item]) : undefined,
      value: form[item as keyof Partial<TCustomerAdd>],
      rules,
      uncommonError: [...CUSTOMER_VALIDATION_FIELDS, ...CUSTOMER_COMMON_VALIDATION_FIELDS].includes(item)
    });

    if (errors?.length) {
      errorsUI = [
        ...errorsUI,
        ...errors
      ];
    }
  });

  return errorsUI;
};
