import { getQueryParam } from './getQueryParam';

export const getXDomainHeader = () => {
  const queryParamDomain = getQueryParam('domain');
  const queryParamBroker = getQueryParam('broker');
  const user = sessionStorage.getItem('user');
  const userRole = user ? JSON.parse(user)?.role?.name : null;

  if (userRole === 'manager' && queryParamDomain && queryParamBroker) {

    return JSON.stringify({
      'props': { 'tenant': 'poland', 'agency': queryParamBroker, 'branch' : queryParamDomain },
      'meta': { 'tenant': 'poland', 'agency': queryParamBroker, 'branch' : queryParamDomain }
    });
  }
};
